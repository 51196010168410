import React from 'react';
import { useRouter } from 'next/router';
import { Heading } from '@components/common';
import { ErrorLayout } from '@components/layouts';
import { Text } from '@chakra-ui/react';

export default function Error404Page(): JSX.Element | null {
  const { asPath, push } = useRouter();
  const [renderPage, setRenderPage] = React.useState(false);

  React.useEffect(() => {
    const isLastActiveRedirect = asPath.includes('last-active-redirect=true');

    if (isLastActiveRedirect) {
      void push('/');
    } else {
      setRenderPage(true);
    }
  }, [push, asPath]);

  if (!renderPage) {
    return null;
  }

  return (
    <ErrorLayout>
      <Heading as='h1' size='2xl' mb={2}>
        404
      </Heading>
      <Text mb={8}>The page you are looking for could not be found.</Text>
    </ErrorLayout>
  );
}
